@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');


html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  background-color: #5dc7e4;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  height: 100%;
}

h1 {
  font-family: "Abril Fatface", sans-serif;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

hr {
  border: 0.5px solid black;
}

#container {
  padding: 10px;
}

#intro {
  font-style: italic;
}

#intro img {
  max-height: 250px;
  float: left;
  padding-right: 10px;
  padding-bottom: 10px;
}

a {
  color: #234563;
}

#conversation {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

br {
  display: block;
  margin-bottom: 8px;
  content: " ";
}

.conversation-text {
  padding-bottom: 10px;
}

.option-buttons > div {
  display: flex;
  flex-wrap: wrap;
/*  justify-content: center;*/
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.option-button {
  background-color: #fada60; 
  border: none;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 15px;
  margin: 5px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.conversation-item {
  margin-bottom: 25px; 
}

#response-section {
  width: 100%;
  max-width: 700px;
  height: 60px;
  margin-bottom: 80px;
}

.response-box {
  width: 100%; 
  border-radius: 5px;
  font-size: 18px;
  border: 0;
  padding: 5px 8px;
}

.user-response {
  font-style: italic;
  color: #444444;
}